import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Box,
  Card,
  Checkbox,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  CardHeader,
  Grid,
} from '@material-ui/core';
import { Accordion, AccordionDetails, AccordionSummary, Button, List, ListItem, ListItemText } from '@mui/material';
import moment from 'moment';
import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';
import { MCircularProgress } from '../../@material-extend';
import Scrollbar from '../../Scrollbar';
import SearchNotFound from '../../SearchNotFound2';
import { ProductListHead } from '../../e-commerce/product-list';
import ToolbarComponent from '../Toolbar/ToolbarComponent2';
import MoreMenu from '../Menu/MoreMenu';
import Label from '../../Label';

const TableSubItemsList = ({
  title,
  TABLE_HEAD,
  getDocuments,
  handleDelete,
  nameInSpanish,
  nameInEnglish,
  urlToDownloadSheet,
  isMasculine,
  needMassiveCharge,
  isLoading,
  documents,
  isList,
  handleCreateSubItem,
  handleClickItem,
  handleClickSubItem,
  tableStyle,
  dontNeedCheckBox,
  dontNeedCreateButton,
  handleCreateItem,
  handleEditItem,
  handleDuplicateItem,
  needSettings,
  anotherButton,
  anotherTextButton,
  nameDocument,
}) => {
  const history = useHistory();

  const { userProfile } = useSelector((state) => state.user);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [selectedId, setSelectedId] = useState('');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('companyName');

  const filterDocuments = applySortFilter(documents, getComparator(order, orderBy), filterName, nameDocument);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - documents.length) : 0;
  const isProductNotFound = filterDocuments.length === 0;

  const [expanded, setExpanded] = useState('');

  const rowStyle = {
    cursor: 'pointer',
    background: '#F4F6F8',
  };

  const handleChange = (panel, row) => (event, newExpanded) => {
    handleClickItem(row);
    setExpanded(newExpanded ? panel : false);
  };

  const handleClick = (event, name, id) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);

    setSelectedId(id);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = documents.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const onHandleClickSubItem = (subItem) => {
    handleClickSubItem(subItem);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (getDocuments) getDocuments();
  }, [getDocuments]);

  const renderSwitchName = (row, el) => {
    /* Si es una fecha muestrela como tal, si es un array, normal */
    // {el.isDate ? moment(row[el.id]).fromNow() : el.isDate ? row[el.id] : row[el.id]}

    if (el.isDate) {
      return moment(row[el.id]).fromNow();
    }
    if (el.isArray) {
      return row[el.isArray][el.id];
    }
    return row[el.id];
  };

  const onClickNormalItem = (row, id) => {
    if (handleClickItem) {
      handleClickItem(row);
      return;
    }
    history.push(`/dashboard/${nameInEnglish}/${id}/detail`);
  };

  // eslint-disable-next-line arrow-body-style
  const renderNormalItem = (row, el, id) => {
    return (
      <TableCell
        key={`${id}-${el.id}`}
        onClick={() => {
          onClickNormalItem(row, id);
        }}
        align="left"
      >
        {renderSwitchName(row, el)}
      </TableCell>
    );
  };

  // eslint-disable-next-line arrow-body-style
  const renderListItems = (row, el, id) => {
    const isOwner = row.rootUser === userProfile.uid;

    return (
      <TableCell key={`${id}-${el.id}`} align="left"      >
        <Accordion
          key={`accordion-${id}`}
          sx={{ width: '100%' }}
          expanded={expanded === id}
          onChange={handleChange(id, row)}
          
        >
          <AccordionSummary
            key={`ac-summary-${id}`}
            expandIcon={<Icon key={`ic-${id}`} icon={'ic:outline-expand-more'} />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
            sx={{
              '&:hover': {
                backgroundColor: '#ECECEC !important',
              },backgroundColor: '#fff'
            }}
          >
            <Typography sx={{ color: 'black', fontWeight: 'bold' }}>
              {renderSwitchName(row, el)}
            </Typography>
            <Label color={isOwner ? 'success' : 'info'} sx={{ textTransform: 'uppercase', mb: 1, ml: 'auto', mr: 3 }}>
              {isOwner ? 'Propietari' : 'Invitado'}
            </Label>
          </AccordionSummary>
          <AccordionDetails>
            <List key={`list2-${id}`} component="nav" aria-label="mailbox folders" sx={{ marginLeft: '20px' }}>
            
              {row[isList]
                ? Object.keys(row[isList]).map((el, idx) => (
                    <ListItem
                      key={`item2-${id}-${idx}`}
                      onClick={() => {
                        onHandleClickSubItem({ id: el, ...row[isList][el] });
                      }}
                      sx={[
                        { marginLeft: '8px' },
                        {
                          '&:hover': {
                            color: 'black',
                            backgroundColor: '#ECECEC',
                          },
                        },
                      ]}
                    >
                      <ListItemText key={`text ${id}`} primary={row[isList][el].name} />
                    </ListItem>
                  ))
                : null}
            </List>
          </AccordionDetails>
        </Accordion>
      </TableCell>
    );
  };

  const renderSwitchItem = (row, el, id) => {
    if (isList && el.id !== 'typeUser') {
      return renderListItems(row, el, id);
    }
    return renderNormalItem(row, el, id);
  };

  const validateRows = () => {
    if ((documents.length - 1) % rowsPerPage === 0 && page !== 0) {
      setPage(page - 1);
    }
  };

  // eslint-disable-next-line arrow-body-style
 

  return (
    <Card sx={tableStyle}>
      {title && (
        <CardHeader
          title={
            <Grid container spacing={3}>
              <Grid justifyContent="flex-start" alignItems="center" item>
                <Typography variant="h6">{title}</Typography>
              </Grid>
            </Grid>
          }
        />
      )}

      <ToolbarComponent
        numSelected={selected.length}
        filterName={filterName}
        onFilterName={handleFilterByName}
        id={selected.length === 1 ? selectedId : null}
        handleDelete={handleDelete}
        setSelected={setSelected}
        name={nameInSpanish}
        isMasculine={isMasculine}
        nameInEnglish={nameInEnglish}
        needMassiveCharge={needMassiveCharge}
        urlToDownloadSheet={urlToDownloadSheet}
        dontNeedCreateButton={dontNeedCreateButton}
        handleCreateItem={handleCreateItem}
        anotherButton={anotherButton}
        anotherTextButton={anotherTextButton}
      />

      <Scrollbar    sx={{
                   
                   backgroundColor: '#fff',
            
                 }}>
        <TableContainer sx={{ marginTop: '-57px' }}>
          <Table >
            <ProductListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={documents.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
              dontNeedCheckBox={dontNeedCheckBox}
            />

            <TableBody  >
              {filterDocuments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                const { id } = row;
                const isItemSelected = selected.indexOf(id) !== -1;

                return (
                  <TableRow hover key={id} tabIndex={-1} sx={isList ? rowStyle : { cursor: 'pointer' }}>
                    {!dontNeedCheckBox && (
                      <TableCell key={`cell-${id}`} padding="checkbox" onClick={(event) => handleClick(event, id, id)}>
                        <Checkbox checked={isItemSelected} />
                      </TableCell>
                    )}

{TABLE_HEAD.map((el) => 
  el.id !== '' ? renderSwitchItem(row, el, id) : null
)}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={20} key={`empty`} />
                </TableRow>
              )}
            </TableBody>
            {isProductNotFound && !isLoading && (
              <TableBody>
                <TableRow>
                  <TableCell key={`notFound`} align="center" colSpan={20}>
                    <Box sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            {isLoading && (
              <TableBody>
                <TableRow>
                  <TableCell key={`isLoading`} align="center" colSpan={20}>
                    <Box sx={{ py: 3 }}>
                      <MCircularProgress />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>


    </Card>
  );
};

// -----------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, nameDocument) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((_user) => {
      console.log(_user);
      return _user[nameDocument]?.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

TableSubItemsList.propTypes = {
  title: PropTypes.string,
  TABLE_HEAD: PropTypes.array,
  getDocuments: PropTypes.func,
  handleDelete: PropTypes.func,
  nameInSpanish: PropTypes.string,
  nameInEnglish: PropTypes.string,
  urlToDownloadSheet: PropTypes.string,
  isMasculine: PropTypes.bool,
  needMassiveCharge: PropTypes.bool,
  isLoading: PropTypes.bool,
  documents: PropTypes.array,
  isList: PropTypes.string,
  handleCreateSubItem: PropTypes.func,
  handleClickItem: PropTypes.func,
  handleClickSubItem: PropTypes.func,
  tableStyle: PropTypes.object,
  dontNeedCheckBox: PropTypes.bool,
  dontNeedCreateButton: PropTypes.bool,
  handleCreateItem: PropTypes.func,
  handleEditItem: PropTypes.func,
  handleDuplicateItem: PropTypes.func,
  needSettings: PropTypes.bool,
  anotherButton: PropTypes.func,
  anotherTextButton: PropTypes.string,
  nameDocument: PropTypes.string,
};

export default TableSubItemsList;
